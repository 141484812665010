<template>
  <b-modal
    id="school-employee-leave-modal"
    size="lg"
    ok-only
    :title="$t('LEAVE.INDIVIDUAL.TITLE')"
  >
    <SchoolEmployeeIndividualLeaveTable :leaves="leaves" :is-busy="isBusy">
    </SchoolEmployeeIndividualLeaveTable>

    <div class="d-flex justify-content-end" v-if="leaves.length">
      <b-pagination
        pills
        size="lg"
        class="pt-5"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        @change="$emit('page-change', $event)"
      ></b-pagination>
    </div>
  </b-modal>
</template>

<script>
import SchoolEmployeeIndividualLeaveTable from "@/modules/school/components/management/attendance/SchoolEmployeeIndividualLeaveTable";

export default {
  name: "SchoolEmployeeIndividualLeaveModal",
  components: { SchoolEmployeeIndividualLeaveTable },
  props: {
    leaves: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    pagination: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped></style>
