<template>
  <b-table
    :fields="fields"
    :items="leaves"
    :busy="isBusy"
    :responsive="true"
    table-class="table-head-custom table-vertical-center"
    show-empty
    hover
  >
    <template #table-busy>
      <AppLoader></AppLoader>
    </template>

    <template #empty>
      <AppEmptyList text-top="No Applications" text-bottom="..."></AppEmptyList>
    </template>
  </b-table>
</template>

<script>
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import AppLoader from "@/modules/core/components/loaders/AppLoader";

export default {
  name: "SchoolEmployeeIndividualLeaveTable",
  components: { AppLoader, AppEmptyList },
  props: {
    leaves: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: this.$t("LEAVE.TYPE"),
          stickyColumn: true,
          variant: "light",
        },
        { key: "from_date", label: this.$t("EDUCATION.FROM") },
        { key: "to_date", label: this.$t("EDUCATION.TO") },
        { key: "unit.requested", label: this.$t("HOLIDAY.DAY") },
        { key: "reason", label: this.$t("ENTITLEMENT.REASON") },
        { key: "status", label: this.$t("JOB.STATUS") },
      ],
    };
  },
};
</script>

<style scoped></style>
